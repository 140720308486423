import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContainer from '../components/pageContainer';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  line-height: 1.2;
  color: #064f71;
  font-size: 2.2rem;

  @media (min-width: 600px) {
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const Description = styled.p`
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <PageContainer>
        <Title>NOT FOUND</Title>
        <Description>You just hit a route that doesn&#39;t exist... the sadness.</Description>
      </PageContainer>
    </Container>
  </Layout>
);

export default NotFoundPage;
